// Beverages
// Hot Drinks
import latte1 from "./assets/SAJ Hot Drink 001.jpg";
import latte2 from "./assets/SAJ Hot Drink 002.jpg";
import latte3 from "./assets/SAJ Hot Drink 003.jpg";
import latte4 from "./assets/SAJ Hot Drink 004.jpg";
import latte5 from "./assets/SAJ Hot Drink 005.jpg";
import latte6 from "./assets/SAJ Hot Drink 006.jpg";
import latte7 from "./assets/SAJ Hot Drink 007.jpg";
import doubleEspresso from "./assets/SAJ Double Espresso-min.jpg";
import hotAmericano from "./assets/SAJ Hot Americano -min (1).jpg";
import cortado from "./assets/Cortado-min (1).jpg";
import v60Hot from "./assets/v60Hot.jpg";
import chocalatteMocha from "./assets/SAJ Hot Chocolate-min.jpg";

// Iced Drinks
import icedV60 from "./assets/IcedV60.jpg";
import icedLatte from "./assets/iceLatte.jpg";
import icedPistachioLatte from "./assets/SAJ Iced Pistacio Latte-min.jpg";
import americano from "./assets/americano.jpg";
import icedSpanishLatte from "./assets/SAJ Iced Spanish Latte-min.jpg";
import icedCaramella from "./assets/icedCaramella.jpg";
import icedMocha from "./assets/icedMocha.jpg";

// Mojitos
import strawberryMojito from "./assets/strawberryMojito.jpg";
import blueCuracaoMojito from "./assets/blueCuracaoMojito.jpg";
import passionFruitMojito from "./assets/passionFruitMojito.jpg";

// Frappes
import pistachioFrappe from "./assets/Pistachio Frappe-min.jpg";
import coffeeFrappe from "./assets/frappe.jpg";
import caramelFrappe from "./assets/caramelFrappe.jpg";
import chocoloateFrappe from "./assets/chocolateFrappe.jpg";
import mochaFrappe from "./assets/SAJ Mocha Frappe-min.jpg";
import strawberryFrappe from "./assets/Frappe 005-min.jpg";

// Special Cakes
import carrotCake from "./assets/specialcakes/CARROT CAKE 130AED.jpg";
import carrotMonoCake from "./assets/specialcakes/CARROT MONO CAKE 20 AED.jpg";
import chocolateCheeseCake from "./assets/specialcakes/CHOCLATE CHEESE CAKE 130.jpg";
import fruitTartCake from "./assets/specialcakes/FRUIT TART CAKE 150 AED.jpg";
import lemonBlueberryCake from "./assets/specialcakes/LEMON BLUEBERRY CAKE 130 AED.jpg";
import lemonBlueberryMonoCake from "./assets/specialcakes/LEMON BLUEBERRY MONO CAKE 20 AED.jpg";
import mappleMonoCake from "./assets/specialcakes/mapple mono cake 20 AED.jpg";
import monoCakeChocolate from "./assets/specialcakes/MONO CAKE CHOCOLATE 20 AED.jpg";
import pistachioMonoCake from "./assets/specialcakes/PISTACHIO MONO CAKE 20 AED.jpg";
import pistachioRaspberryCake from "./assets/specialcakes/PISTACHIO RASPEBERRY CAKE 130 AED.jpg";
import pumpkinCake from "./assets/specialcakes/PUMPKIN CAKE 130.jpg";
import REDVELVETCHEESECAKE from "./assets/specialcakes/RED VELVET CHEESE CAKE 80 AED.jpg";
import PrincessPisthachioCake from "./assets/specialcakes/SAJ Princess Pisthachio Cake 210 AED.jpg";
import PumkinMonoCake from "./assets/specialcakes/SAJ Pumkin Mono Cake 20 AED.jpg";
import tiramisuThurtha from "./assets/specialcakes/SAJ Tiramisu Thurtha.jpg";
import TARTMINICAKE from "./assets/specialcakes/TART MINI CAKE 20 AED.jpg";
import mappleCake from "./assets/specialcakes/mapple cake.jpg";
import ChocolateCrunchyCake from "./assets/specialcakes/CHOCOLATE CRUNCHY CAKE 180 AED.JPG";
import Img1 from "./assets/specialcakes/IMG_3001.JPG";
import Img2 from "./assets/specialcakes/IMG_3207.JPG";
import Img3 from "./assets/specialcakes/IMG_9038.JPG";
import Img4 from "./assets/specialcakes/IMG_9611.JPG";
import Img5 from "./assets/specialcakes/IMG_9618.JPG";
import vanillaMixBerryCake from "./assets/specialcakes/VANNILA MIX BERRY CAKE 210 AED.JPG";


// Special Desserts
import assortedDessertGlass from "./assets/special desserts/ASSORTED DESSERT GLASS  10 AED.jpg";
import bruleeMangoBowl from "./assets/special desserts/BRULEE MANGO BOWL 105 AED.jpg";
import bruleePistachioBowl from "./assets/special desserts/BRULEE PISTACHIO BOWL 120 AED.jpg";
import bruleeSaffroBowl from "./assets/special desserts/BRULEE SAFFRO BOWL 105 AED.jpg";
import bruleeVannillaBowl from "./assets/special desserts/BRULEE VANNILA BOWL 105 AED.jpg";
import princessPistachioBowl from "./assets/special desserts/PRINCESS PISTACHIO BOWL 120 AED.jpg";
import exoticTartMix from "./assets/special desserts/EXOTIC TART MIX 126 AED.jpg";
import dreamCake from  "./assets/special desserts/chocolate_dream_cake.jpg";

// Cakes
import caramelThurthaBig from "./assets/cakes/CARAMEL THURTHA BIG 150 AED-min.jpg";
import caramelThurthaSmall from "./assets/cakes/CARAMEL THURTHA SMALL 85 AED-min.jpg";
import chocolateCake from "./assets/cakes/CHOCOLATE CAKE 150 AED-min.jpg";
import frenchHoneyCakeRegular from "./assets/cakes/FRENCH HONEY CAKE REGULAR 100 AED-min.jpg";
import fruitCakeSmall from "./assets/cakes/FRUIT CAKE SMALL 50 AED-min.jpg";
import koreanCake from "./assets/cakes/KOREAN CAKE 45 DH-min.jpg";
import koreanCake1 from "./assets/cakes/KOREAN CAKE 45DH-min.jpg";
import koreanCake2 from "./assets/cakes/KOREN CAKE 45 AED-min.jpg";
import flakesCake from "./assets/cakes/FLAKES CAKE 105 AED.JPG";
import lotusCake from "./assets/cakes/LOTUS CAKE 160 AED.JPG";
import nutellaCakeRegular from "./assets/cakes/NUTELLA CAKE REGULAR 105 AED.JPG";
import strawberryCake from "./assets/cakes/STRAWBERRY CAKE 130 AED.JPG";

// orientalsweets
import CHIPSIKO from "./assets/orientalsweets/1. CHIPSIKO 90 AED.jpg";
import GALAXY from "./assets/orientalsweets/2. GALAXY 70 AED.jpg";
import SNIKERS from "./assets/orientalsweets/3. SNIKERS 70 AED.jpg";
import CAKECAPPUCCINO from "./assets/orientalsweets/4. CAKE CAPPUCCINO 57 AED.jpg";
import CAKEHAZELNUT from "./assets/orientalsweets/5. CAKE HAZELNUT 52 AED.jpg";
import Cakeshahriyatoffy from "./assets/orientalsweets/6. Cake shahriya toffy 47 AED.jpg";
import CUPCAKELOTUS from "./assets/orientalsweets/7. CUP CAKE LOTUS 57 AED.jpg";
import TRUFFLESNUTELLA from "./assets/orientalsweets/8. TRUFFLES NUTELLA 57 AED.jpg";
import TRUFFLESOREO from "./assets/orientalsweets/9. TRUFFLES OREO 57 AED.jpg";
import JULLASHHAZELNUT from "./assets/orientalsweets/10. JULLASH HAZELNUT 50 AED.jpg";
import CHEESECAKEBERRIES from "./assets/orientalsweets/11. CHEESE CAKE BERRIES 65 AED.jpg";
import CHEESECAKELOTUS from "./assets/orientalsweets/12. CHEESE CAKE LOTUS 65 AED.jpg";
import MINITIRAMISU from "./assets/orientalsweets/13. MINI TIRAMISU 55 AED.jpg";
import CHEESEOREOCHOCOLATE from "./assets/orientalsweets/14. CHEESE OREO CHOCOLATE 60 AED.jpg";
import CHEESECAKEROCKYMIX from "./assets/orientalsweets/15. CHEESE CAKE ROCKY MIX 65 AED.jpg";
import CHEESEBALLLOTUS from "./assets/orientalsweets/16. CHEESE BALL LOTUS 65 AED.jpg";
import MINICAKE from "./assets/orientalsweets/17. MINI CAKE 50 AED.jpg";
import REDVELVETMIX from "./assets/orientalsweets/18. REDVELVET MIX 63 AED.jpg";
import PURPLEVELVET from "./assets/orientalsweets/19. PURPLE VELVET 65 AED.jpg";
import OREOCAKEMIX from "./assets/orientalsweets/20. OREO CAKE MIX 60 AED.jpg";
import ECLAIRLOTUS from "./assets/orientalsweets/21. ECLAIR LOTUS 57 AED.jpg";
import ECLAIRSPECIALMIX from "./assets/orientalsweets/22. ECLAIR SPECIAL MIX 63 AED.jpg";
import ECLAIRTOFFY from "./assets/orientalsweets/23. ECLAIR TOFFY 40 AED.jpg";
import LOTUSPUDDING from "./assets/orientalsweets/24. LOTUS PUDDING 47 AED.jpg";
import OREOPUDDING from "./assets/orientalsweets/25. OREO PUDDING 47.jpg";
import PISTACHIOPUDDING from "./assets/orientalsweets/26. PISTACHIO PUDDING 47 AED.jpg";
import MANGOPUDDING from "./assets/orientalsweets/27. MANGO PUDDING 42 AED (2).jpg";
import RUMANPUDDING from "./assets/orientalsweets/28. RUMAN PUDDING 42 AED.jpg";
import PUDDINGMIX from "./assets/orientalsweets/29. PUDDING MIX 48 AED.jpg";
import UMMNASER from "./assets/orientalsweets/30. UMM-NASER 57 AED.jpg";
import milkCakeSaffron from "./assets/orientalsweets/MILK CAKE SAFFRON 55 AED.JPG";
import eclairSaffron from "./assets/orientalsweets/IMG_0172.jpg";
import cakeBrownie from "./assets/orientalsweets/IMG_0214.jpg";
import trufflesHazelNut from "./assets/orientalsweets/IMG_0300.jpg";
import galaxyNuts from "./assets/orientalsweets/IMG_0404.jpg";

//Savouries sweet
import sandwichSpecialSmall from "./assets/savouries/SANDWICH SPECIAL SMALL   63 AED.png";
import sandwichSpecialLarge from "./assets/savouries/SANDWICH SPECIAL LARGE 95 AED.jpg";
import sandwichFakher from "./assets/savouries/SANDWICH FAKHER 75 AED.jpg";
import miniSandwich from "./assets/savouries/MINI SANDWICH 70 AED.jpg";
import musakhanRoll from "./assets/savouries/MUSAKHAN ROLL 50 AED.png";
import fatayerMixLarge from "./assets/savouries/FATAYER MIX LARGE 58 AED.jpg";
import miniCroissonLebanahMix from "./assets/savouries/MINI CROISSON LEBANAH MIX 85.png";
import fatayerMixSpecial from "./assets/savouries/FATAYER SPECIAL 90 AED.jpg";
import croissonLebanah from "./assets/savouries/CROISSON LEBANAH 47 AED.png";
import miniFatayerLarge from "./assets/savouries/MINI FATAYER LARGE 55 AED.jpg";
import fatayerShawaya from "./assets/savouries/FATAYER SHAWAYA 53 AED.jpg";
import miniBites from "./assets/savouries/MINI BITES 75 AED.jpg";
import fatayerFrancy from "./assets/savouries/FATAYER FRANCY 55 AED.jpg";
import crooisantSpecialMix from "./assets/savouries/CROISSANT SPECIAL MIX 50AED (1).jpg";
import fatayerMixSeniaRegular from "./assets/savouries/FATAYER MIX SENIA REGULAR 160AED.jpg";
import warganabRegular from "./assets/savouries/WARGANAB REGULAR 52 AED.JPG";
import warganabSpecial from "./assets/savouries/WARGANAB SPECIAL 63 AED.jpg";
import tortillaWraps from "./assets/savouries/TORTILLA WRAPS 84 AED.jpg";
import fualaSandwich from "./assets/savouries/IMG_0121.jpg";

//arabic sweet
import SHAHADBASBOOSAMIX from "./assets/arabiansweets/1. SHAHAD BASBOOSA MIX 65 AED.jpg";
import BASBOOSADATESSLICE from "./assets/arabiansweets/2. BASBOOSA DATES SLICE 50 AED.jpg";
import BASBOOSAKISHTA from "./assets/arabiansweets/3. BASBOOSA KISHTA 47 AED.jpg";
import BASBOOSAPISTACHIOSLICE from "./assets/arabiansweets/4. BASBOOSA PISTACHIO SLICE 50 AED.jpg";
import BASBOOSASPANIYA from "./assets/arabiansweets/5. BASBOOSA SPANIYA 52 AED.jpg";
import THAMARIA from "./assets/arabiansweets/6. THAMARIA 63 AED.jpg";
import RINGCAKESAFFRON from "./assets/arabiansweets/7. RING CAKE SAFFRON 55 AED.jpg";
import ENGLISHCARAMEL from "./assets/arabiansweets/8. ENGLISH CARAMEL 70 AED.jpg";
import GHEEMATHJIBEN from "./assets/arabiansweets/9. GHEEMATH JIBEN 47 AED.jpg";
import CUPKUNAFAMIX from "./assets/arabiansweets/10. CUP KUNAFA MIX 57 AED.jpg";
import CAKESHAHRIYA from "./assets/arabiansweets/11. CAKE SHAHRIYA 45 AED.jpg";
import BISCUITKUWAITI from "./assets/arabiansweets/12. BISCUIT KUWAITI 50 AED.jpg";
import BUTTERCOOKIESREGULAR from "./assets/arabiansweets/13. BUTTER COOKIES REGULAR 40 AED.jpg";
import KUNAFACUTS from "./assets/arabiansweets/14. KUNAFA CUTS 47 AED.jpg";
import RUMANBISCUIT from "./assets/arabiansweets/15. RUMAN BISCUIT 57 AED.jpg";
import KHALIYALARGE from "./assets/arabiansweets/16. KHALIYA LARGE 55 AED.jpg";
import BAGLAWA from "./assets/arabiansweets/17. BAGLAWA AED 110.jpg";
import ayoonJamalMix from "./assets/arabiansweets/18. AYOON JAMAL MIX BOX 75 AED.jpg";
import basboosaLabaneh from "./assets/arabiansweets/19. BASBOOSA LABANEH 57 AED.JPG";
import mamoolThamar from "./assets/arabiansweets/20.MAMOOL THAMAR 63 AED.JPG";
import uraiba from "./assets/arabiansweets/IMG_0264.jpg";

// customised cakes
import cocoMelonThemedCake from "./assets/customised-cakes/WhatsApp Image 2023-10-26 at 19.16.59_91615eda.jpg";
import brideToBeThemedCake from "./assets/customised-cakes/WhatsApp Image 2023-10-26 at 19.19.20_22224921.jpg";
import cocoMelonThemedCake1 from "./assets/customised-cakes/WhatsApp Image 2023-10-26 at 19.20.49_33b9a211.jpg"; 
import elissaThemedCake from "./assets/customised-cakes/WhatsApp Image 2023-10-26 at 19.20.50_0ffe368b.jpg";
import nationalServiceThemedCake from './assets/customised-cakes/WhatsApp Image 2023-10-26 at 19.20.51_f36fdccc.jpg';

export const categories = [
  { label: "Hot Drinks", id: "hotDrinks" },
  { label: "Iced Drinks", id: "icedDrinks" },
  { label: "Mojitos", id: "mojitos" },
  { label: "Frappes", id: "frappes" },
];

export const menu = {
  categories: [
    // bevarages
    {
      id: 1,
      name: "Beverages",
      url: "beverages",
      subCategories: [
        {
          id: 1,
          name: "Hot Drinks",
          url: "hot-drinks",
          items: [
            {
              id: 1,
              name: "Cafe Latte",
              price: "AED 16.00",
              img: latte1,
              subCategory: "Hot Drinks",
              description:
                "Smooth and creamy steamed milk with single origin short of espresso",
              query: "hotDrinks",
            },
            {
              id: 2,
              name: "Cappuccino",
              price: "AED 16.00",
              img: latte2,
              category: "Hot Drinks",
              description:
                "Combination of frothed milk blended with single origin espresso shot",
              query: "hotDrinks",
            },
            {
              id: 3,
              name: "Flat White",
              price: "AED 17.00",
              img: latte3,
              category: "Hot Drinks",
              description:
                "Double shot of coffee  extraction with smooth and creamy textured milk",
              query: "hotDrinks",
            },
            {
              id: 4,
              name: "Hot Pistachio Latte",
              price: "AED 20.00",
              img: latte4,
              category: "Hot Drinks",
              description:
                "Sweet latte with combination of pistachio sauce, steamed fresh milk and espresso",
              query: "hotDrinks",
            },
            {
              id: 5,
              name: "Americano",
              price: "AED 14.00",
              img: hotAmericano,
              category: "Hot Drinks",
              description:
                "Single origin Brazilian espresso coffee lengthened with water",
              query: "hotDrinks",
            },
            {
              id: 6,
              name: "Caramella Latte",
              price: "AED 20.00",
              img: latte7,
              category: "Hot Drinks",
              description:
                "Sweet latte with combination of  caramel based  sauce, steamed fresh milk and espresso",
              query: "hotDrinks",
            },
            {
              id: 7,
              name: "Chocolate Latte",
              price: "AED 20.00",
              img: latte6,
              category: "Hot Drinks",
              description:
                "Sweet latte with combination of  Chocolate based  sauce, steamed fresh milk and espresso",
              query: "hotDrinks",
            },
            {
              id: 8,
              name: "Chocolate Mocha",
              price: "AED 21.00",
              img: chocalatteMocha,
              category: "Hot Drinks",
              description: "Rich hot chocolate with coffee",
              query: "hotDrinks",
            },

            {
              id: 9,
              name: "Spanish Latte",
              price: "AED 20.00",
              img: latte5,
              category: "Hot Drinks",
              description:
                "Sweet latte with combination of  special spanish sauce, steamed fresh milk and espresso",
              query: "hotDrinks",
            },
            {
              id: 10,
              name: "Cortado",
              price: "AED 17.00",
              img: cortado,
              category: "Hot Drinks",
              description:
                "Double shot of single origin coffee extraction with creamy and textured milk. Served in 4oz cup",
              query: "hotDrinks",
            },
            {
              id: 11,
              name: "V60",
              price: "AED 25.00",
              img: v60Hot,
              category: "Hot Drinks",
              description:
                "Manual brewing with v60 method that results in a rich, clean & tasteful cup",
              query: "hotDrinks",
            },
            {
              id: 12,
              name: "Double Espresso",
              price: "AED 12.00",
              img: doubleEspresso,
              category: "Hot Drinks",
              description:
                "Double shot of single origin Brazilian espresso coffee",
              query: "hotDrinks",
            },
          ],
        },
        {
          id: 2,
          name: "Iced Drinks",
          url: "iced-drinks",
          items: [
            {
              id: 1,
              name: "Iced Latte",
              price: "AED 16.00",
              img: icedLatte,
              category: "Iced Drinks",
              description: "Fresh milk with shot of espresso over ice",
              query: "icedDrinks",
            },
            {
              id: 2,
              name: "Iced Pistachio Latte",
              price: "AED 23.00",
              img: icedPistachioLatte,
              category: "Iced Drinks",
              description:
                "Sweet latte with combination of  pistachio sauce, fresh milk and espresso over ice",
              query: "icedDrinks",
            },
            {
              id: 3,
              name: "Iced Mocha",
              price: "AED 24.00",
              img: icedMocha,
              category: "Iced Drinks",
              description: "Rich hot chocolate with coffee over ice",
              query: "icedDrinks",
            },
            {
              id: 4,
              name: "Iced Americano",
              price: "AED 14.00",
              img: americano,
              category: "Iced Drinks",
              description:
                "Single origin Brazilian espresso coffee lengthened with water and ice",
              query: "icedDrinks",
            },
            {
              id: 5,
              name: "Iced Spanish Latte",
              price: "AED 23.00",
              img: icedSpanishLatte,
              category: "Iced Drinks",
              description:
                "Sweet latte with combination of spanish sauce, fresh milk and espresso over ice",
              query: "icedDrinks",
            },
            {
              id: 6,
              name: "Iced Caramella",
              price: "AED 23.00",
              img: icedCaramella,
              category: "Iced Drinks",
              description:
                "Sweet latte with combination of caramel based sauce, fresh milk and espresso over ice",
              query: "icedDrinks",
            },
            {
              id: 7,
              name: "Iced V60",
              price: "AED 23.00",
              img: icedV60,
              category: "Iced Drinks",
              description:
                "Manual brewing with v60 method that results in a rich, clean & tasteful cup  with ice",
              query: "icedDrinks",
            },
          ],
        },
        {
          id: 3,
          name: "Mojitos",
          url: "mojitos",
          items: [
            {
              id: 20,
              name: "Strawberry Mojito",
              price: "AED 25.00",
              img: strawberryMojito,
              category: "Mojitos",
              description: "A refreshing aerated mojito with strawberry flavor",
              query: "mojitos",
            },
            {
              id: 21,
              name: "Blue Curacao Mojito",
              price: "AED 25.00",
              img: blueCuracaoMojito,
              category: "Mojitos",
              description:
                "A refreshing aerated mojito with blue curacao flavor",
              query: "mojitos",
            },
            {
              id: 22,
              name: "Passion Fruit Mojito",
              price: "AED 25.00",
              img: passionFruitMojito,
              category: "Mojitos",
              description:
                "A refreshing aerated mojito with Passion fruit flavor",
              query: "mojitos",
            },
          ],
        },
        {
          id: 4,
          name: "Frappes",
          url: "frappes",
          items: [
            {
              id: 23,
              name: "Pistachio Frappe",
              price: "AED 27.00",
              img: pistachioFrappe,
              category: "Frappes",
              description: "Iced blended drink with flavour of Pista",
              query: "frappes",
            },
            {
              id: 24,
              name: "Coffee Frappe",
              price: "AED 26.00",
              img: coffeeFrappe,
              category: "Frappes",
              description: "Iced blended drink with flavour of coffee",
              query: "frappes",
            },
            {
              id: 25,
              name: "Caramel Frappe",
              price: "AED 26.00",
              img: caramelFrappe,
              category: "Frappes",
              description: "Iced blended drink with flavour of caramel",
              query: "frappes",
            },
            {
              id: 26,
              name: "Chocolate Frappe",
              price: "AED 26.00",
              img: chocoloateFrappe,
              category: "Frappes",
              description: "Iced blended drink with flavour of chocolate",
              query: "frappes",
            },
            {
              id: 27,
              name: "Mocha Frappe",
              price: "AED 26.00",
              img: mochaFrappe,
              category: "Frappes",
              description: "Iced blended drink with flavour of mocha",
              query: "frappes",
            },
            {
              id: 28,
              name: "Strawberry Frappe",
              price: "AED 26.00",
              img: strawberryFrappe,
              category: "Frappes",
              description: "Iced blended drink with flavour of strawberry",
              query: "frappes",
            },
          ],
        },
      ],
    },
    // special cakes
    {
      id: 2,
      name: "Special Cakes",
      url: "special-cakes",
      items: [
        {
          id: 1,
          name: "Pistachio Mono Cake",
          price: "AED 20.00",
          img: pistachioMonoCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 2,
          name: "Carrot Mono Cake",
          price: "AED 20.00",
          img: carrotMonoCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 3,
          name: "Chocolate Cheese Mono Cake",
          price: "AED 20.00",
          img: monoCakeChocolate,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 4,
          name: "Lemon Blueberry Mono Cake",
          price: "AED 20.00",
          img: lemonBlueberryMonoCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 5,
          name: "Mapple Mono Cake",
          price: "AED 20.00",
          img: mappleMonoCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 6,
          name: "Pumpkin Mono Cake",
          price: "AED 20.00",
          img: PumkinMonoCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 7,
          name: "Mini Tart Cake",
          price: "AED 20.00",
          img: TARTMINICAKE,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 8,
          name: "Pistachio Raspberry Cake",
          price: "AED 130.00",
          img: pistachioRaspberryCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 9,
          name: "Chocolate Cheese Cake",
          price: "AED 130.00",
          img: chocolateCheeseCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 10,
          name: "Lemon Blueberry Cake",
          price: "AED 130.00",
          img: lemonBlueberryCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 11,
          name: "pumpkin Cake",
          price: "AED 130.00",
          img: pumpkinCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 12,
          name: "Mapple Cake",
          price: "AED 130.00",
          img: mappleCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 13,
          name: "Carrot Cake",
          price: "AED 130.00",
          img: carrotCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 14,
          name: "Red Velvet Cheese Cake",
          price: "AED 80.00",
          img: REDVELVETCHEESECAKE,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 15,
          name: "Princess Pistachio Cake",
          price: "AED 210.00",
          img: PrincessPisthachioCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 16,
          name: "Fruit Tart Cake",
          price: "AED 100.00",
          img: fruitTartCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 17,
          name: "Tiramisu Cake",
          price: "AED 180.00",
          img: tiramisuThurtha,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 18,
          name: "Chocolate Crunchy Cake",
          price: "AED 180.00",
          img: ChocolateCrunchyCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 19,
          name: "princess pinata cake",
          price: "AED 260.00",
          img: Img1,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 20,
          name: "mango cake",
          price: "AED 180.00",
          img: Img2,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 21,
          name: "Fruit and Chocolate Cake",
          price: "AED 180.00",
          img: Img3,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 22,
          name: "Chocolate Crunchy Pinata Cake",
          price: "AED 220.00",
          img: Img4,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 23,
          name: "Vanilla Mix Berry Pinata Cake",
          price: "AED 260.00",
          img: Img5,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
        {
          id: 24,
          name: "Vanilla mix berry cake",
          price: "AED 210.00",
          img: vanillaMixBerryCake,
          category: "Special Cakes",
          description: "",
          query: "specialCakes",
        },
      ],
    },
    // special desserts
    {
      id: 3,
      name: "Special Desserts",
      url: "special-desserts",
      items: [
        {
          id: 1,
          name: "Princess Pistachio Bowl",
          price: "AED 120.00",
          img: princessPistachioBowl,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 2,
          name: "Brulee Vanilla Bowl",
          price: "AED 105.00",
          img: bruleeVannillaBowl,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 3,
          name: "Brulee Saffron Bowl",
          price: "AED 105.00",
          img: bruleeSaffroBowl,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 4,
          name: "Brulee Mango Bowl",
          price: "AED 105.00",
          img: bruleeMangoBowl,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 5,
          name: "Brulee Pistachio Bowl",
          price: "AED 105.00",
          img: bruleePistachioBowl,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 6,
          name: "Assorted Dessert Glass",
          price: "AED 10.00",
          img: assortedDessertGlass,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 7,
          name: "Exotic Tart Mix ",
          price: "AED 80.00",
          img: exoticTartMix,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        },
        {
          id: 8,
          name: "Chocolate Dream Cake",
          price: "AED 75.00",
          img: dreamCake,
          category: "Special Desserts",
          description: "",
          query: "specialDesserts",
        }
      ],
    },
    // cakes
    {
      id: 4,
      name: "Cakes",
      url: "cakes",
      items: [
        {
          id: 1,
          name: "Chocolate Cake",
          price: "AED 150.00",
          img: chocolateCake,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 2,
          name: "Caramel Cake Large",
          price: "AED 150.00",
          img: caramelThurthaBig,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 3,
          name: "French Honey Cake",
          price: "AED 100.00",
          img: frenchHoneyCakeRegular,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 4,
          name: "Fruit Cake Regular",
          price: "AED 50.00",
          img: fruitCakeSmall,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 5,
          name: "Caramel Cake Regular",
          price: "AED 85.00",
          img: caramelThurthaSmall,
          category: "Cakes",
          description: "",
          query: "cakes",
        },

        {
          id: 6,
          name: "Korean Cake",
          price: "AED 45.00",
          img: koreanCake,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 7,
          name: "Korean Cake",
          price: "AED 50.00",
          img: koreanCake1,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 8,
          name: "Korean Cake",
          price: "AED 45.00",
          img: koreanCake2,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 9,
          name: "Flakes Cake ",
          price: "AED 105.00",
          img: flakesCake,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 10,
          name: "Lotus Cake",
          price: "AED 160.00",
          img: lotusCake,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 11,
          name: "Nutella Cake Regular",
          price: "AED 105.00",
          img: nutellaCakeRegular,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
        {
          id: 12,
          name: "Strawberry Cake",
          price: "AED 130.00",
          img: strawberryCake,
          category: "Cakes",
          description: "",
          query: "cakes",
        },
      ],
    },
    {
      id: 6,
      name: "Customised Cakes",
      url: "customised-cakes",
      items: [
        {
          id: 1,
          name: "Coco Melon Themed Cake",
          price: "AED 300.00",
          img: cocoMelonThemedCake,
          category: "Customised Cakes",
          description: "",
          query: "customisedCakes",
        },
        {
          id: 2,
          name: "Bride To Be Themed Cake",
          price: "AED 650.00",
          img: brideToBeThemedCake,
          category: "Customised Cakes",
          description: "",
          query: "customisedCakes",
        },
        {
          id: 3,
          name: "Coco Melon Themed Cake",
          price: "AED 550.00",
          img: cocoMelonThemedCake1,
          category: "Customised Cakes",
          description: "",
          query: "customisedCakes",
        },
        {
          id: 4,
          name: "Elissa Themed Cake",
          price: "AED 380.00",
          img: elissaThemedCake,
          category: "Customised Cakes",
          description: "",
          query: "customisedCakes",
        },
        {
          id: 5,
          name: "National Service Themed Cake",
          price: "AED 190.00",
          img: nationalServiceThemedCake,
          category: "Customised Cakes",
          description: "",
          query: "customisedCakes",
        },
        
      ],
    },
    // sweets
    {
      id: 5,
      name: "Sweets",
      url: "sweets",
      subCategories: [
        //oriental sweet
        {
          id: 1,
          name: "oriental sweets",
          url: "oriental-sweets",
          items: [
            {
              id: 1,
              name: "CAKE CAPPUCCINO",
              price: "AED 57.00",
              img: CAKECAPPUCCINO,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 2,
              name: "CHEESE CAKE BERRIES",
              price: "AED 65.00",
              img: CHEESECAKEBERRIES,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 3,
              name: "CHEESE CAKE LOTUS",
              price: "AED 65.00",
              img: CHEESECAKELOTUS,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 4,
              name: "CHEESE OREO CHOCOLATE",
              price: "AED 60.00",
              img: CHEESEOREOCHOCOLATE,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 5,
              name: "MINI TIRAMISU",
              price: "AED.55.00",
              img: MINITIRAMISU,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 6,
              name: "PURPLE VELVET",
              price: "AED 65.00",
              img: PURPLEVELVET,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 7,
              name: "OREO CAKE MIX",
              price: "AED 60.00",
              img: OREOCAKEMIX,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 8,
              name: "RED VELVET MIX",
              price: "AED 63.00",
              img: REDVELVETMIX,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 9,
              name: "MINI CAKE",
              price: "AED 50.00",
              img: MINICAKE,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 10,
              name: "Cake shariya toffy",
              price: "AED 47",
              img: Cakeshahriyatoffy,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 11,
              name: "CHEESE BALL LOTUS",
              price: "AED 65.00",
              img: CHEESEBALLLOTUS,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 12,
              name: "CHEESE CAKE ROCKY MIX ",
              price: "AED 65.00",
              img: CHEESECAKEROCKYMIX,
              category: "orientalsweets",
              description: "Rich hot chocolate with coffee over ice",
              query: "orientalsweets",
            },
            {
              id: 13,
              name: "CUP CAKE LOTUS",
              price: "AED 57.00",
              img: CUPCAKELOTUS,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 14,
              name: "ECLAIR LOTUS",
              price: "AED 57.00",
              img: ECLAIRLOTUS,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 15,
              name: "ECLAIR SPECIAL MIX",
              price: "AED 63.00",
              img: ECLAIRSPECIALMIX,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 16,
              name: "PUDDING MIX",
              price: "AED 48.00",
              img: PUDDINGMIX,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 17,
              name: "ECLAIR TOFFY",
              price: "AED 40.00",
              img: ECLAIRTOFFY,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 18,
              name: "LOTUS PUDDING",
              price: "AED 47.00",
              img: LOTUSPUDDING,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 19,
              name: "MANGO PUDDING",
              price: "AED 42.00",
              img: MANGOPUDDING,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 20,
              name: " RUMAN PUDDING",
              price: "AED 42.00",
              img: RUMANPUDDING,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 21,
              name: "OREO PUDDING",
              price: "AED 47.00",
              img: OREOPUDDING,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 22,
              name: "PISTACHIO PUDDING",
              price: "AED 47 .00",
              img: PISTACHIOPUDDING,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 23,
              name: "UMM-NASER",
              price: "AED 57.00",
              img: UMMNASER,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 24,
              name: "CAKE HAZELNUT",
              price: "AED 52.00",
              img: CAKEHAZELNUT,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 25,
              name: "TRUFFLES OREO",
              price: "AED 57.00",
              img: TRUFFLESOREO,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 26,
              name: "JULLASH HAZELNUT",
              price: "AED 50.00",
              img: JULLASHHAZELNUT,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 27,
              name: "TRUFFLES NUTELLA",
              price: "57 AED",
              img: TRUFFLESNUTELLA,
              category: "orientalsweets",
              description: "Rich hot chocolate with coffee",
              query: "orientalsweets",
            },
            {
              id: 28,
              name: "CHIPSIKO",
              price: "AED 90.00",
              img: CHIPSIKO,
              subCategory: "orientalsweets",
              description: "",
              query: "hotDrinks",
            },
            {
              id: 29,
              name: "GALAXY",
              price: "AED 70.00",
              img: GALAXY,
              category: "orientalsweets",
              description: "",
              query: "s",
            },
            {
              id: 30,
              name: "SNIKERS",
              price: "AED 70.00",
              img: SNIKERS,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 31,
              name: "Milk Cake Saffron",
              price: "AED 55.00",
              img: milkCakeSaffron,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 32,
              name:'Eclair Saffron',
              price:'AED 55.00',
              img: eclairSaffron,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 33,
              name:'Cake Brownie',
              price:'AED 60.00',
              img: cakeBrownie,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 34,
              name:'Truffles Hazelnut',
              price:'AED 57.00',
              img: trufflesHazelNut,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",
            },
            {
              id: 35,
              name:'Galaxy Nuts',
              price:'AED 65.00',
              img: galaxyNuts,
              category: "orientalsweets",
              description: "",
              query: "orientalsweets",              
            }

          ],
        },
        //arabian sweet
        {
          id: 2,
          name: "Arabic Sweets",
          url: "arabic-sweets",
          items: [
            {
              id: 1,
              name: "SHAHAD BASBOOSA MIX ",
              price: "AED 65.00",
              img: SHAHADBASBOOSAMIX,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 2,
              name: "BASBOOSA DATES SLICE ",
              price: "AED 50.00",
              img: BASBOOSADATESSLICE,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 3,
              name: "BASBOOSA KISHTA ",
              price: "AED 47.00",
              img: BASBOOSAKISHTA,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 4,
              name: "BASBOOSA PISTACHIO SLICE",
              price: "AED 50.00",
              img: BASBOOSAPISTACHIOSLICE,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 5,
              name: "BASBOOSA SPANIYA ",
              price: "AED 52.00",
              img: BASBOOSASPANIYA,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 6,
              name: "THAMARIA",
              price: "AED 63.00",
              img: THAMARIA,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 7,
              name: "RING CAKE SAFFRON",
              price: "AED 55.00",
              img: RINGCAKESAFFRON,
              query: "arabiansweets",
            },
            {
              id: 8,
              name: " ENGLISH CARAMEL",
              price: "AED 70.00",
              img: ENGLISHCARAMEL,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 9,
              name: "GHEEMATH JIBEN",
              price: "AED 47.00",
              img: GHEEMATHJIBEN,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 10,
              name: "CUP KUNAFA MIX",
              price: "AED 57.00",
              img: CUPKUNAFAMIX,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 11,
              name: "CAKE SHAHRIYA",
              price: "AED 45.00",
              img: CAKESHAHRIYA,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 12,
              name: "BISCUIT KUWAITI",
              price: "AED 50.00",
              img: BISCUITKUWAITI,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 13,
              name: "BUTTER COOKIES REGULAR",
              price: "AED 40.00",
              img: BUTTERCOOKIESREGULAR,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 14,
              name: "KUNAFA CUTS",
              price: "AED 47.00",
              img: KUNAFACUTS,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 15,
              name: "RUMAN BISCUIT",
              price: "AED 57.00",
              img: RUMANBISCUIT,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 16,
              name: "KHALIYA LARGE",
              price: "AED 55.00",
              img: KHALIYALARGE,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 17,
              name: "BAGLAWA",
              price: "AED 110.00",
              img: BAGLAWA,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 18,
              name: "AYOON JAMAL MIX BOX",
              price: "AED 75.00",
              img: ayoonJamalMix,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 19,
              name: "basboosa Labaneh ",
              price: "AED 57.00",
              img: basboosaLabaneh,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 20,
              name: "mamool Thamar",
              price: "AED 63.00",
              img: mamoolThamar,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            },
            {
              id: 21,
              name: "Uraiba",
              price: "AED 45.00",
              img: uraiba,
              category: "arabiansweets",
              description: "",
              query: "arabiansweets",
            }
          ],
        },
      ],
    },
    // savouries
    {
      id: 5,
      name: "Savouries",
      url: "savouries",
      items: [
        {
          id: 1,
          name: "SANDWICH SPECIAL Regular",
          price: "AED 63.00",
          img: sandwichSpecialSmall,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 2,
          name: "SANDWICH SPECIAL LARGE",
          price: "AED 95.00",
          img: sandwichSpecialLarge,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 3,
          name: "SANDWICH FAKHER",
          price: "AED 75.00",
          img: sandwichFakher,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 4,
          name: "MINI SANDWICH ",
          price: "AED 70.00",
          img: miniSandwich,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 5,
          name: "MUSAKHAN ROLL",
          price: "AED 50.00",
          img: musakhanRoll,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 6,
          name: "FATAYER MIX LARGE",
          price: "AED 58.00",
          img: fatayerMixLarge,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 7,
          name: "Mini Croisson Lebanah Mix",
          price: "AED 85.00",
          img: miniCroissonLebanahMix,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 8,
          name: "FATAYER MIX SPECIAL",
          price: "AED 90.00",
          img: fatayerMixSpecial,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 9,
          name: "CROISSON LABANAH",
          price: "AED 47.00",
          img: croissonLebanah,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 10,
          name: "MINI FATAYER LARGE ",
          price: "AED 55.00",
          img: miniFatayerLarge,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 11,
          name: "FATAYER SHAWAYA",
          price: "AED 53.00",
          img: fatayerShawaya,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 12,
          name: "MINI BITES",
          price: "AED 75.00",
          img: miniBites,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 13,
          name: "Fatayer Francy",
          price: "AED 55.00",
          img: fatayerFrancy,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 14,
          name: "Croissant Special Mix",
          price: "AED 50.00",
          img: crooisantSpecialMix,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 15,
          name: "FATAYER MIX SENIA REGULAR",
          price: "AED 160.00",
          img: fatayerMixSeniaRegular,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 16,
          name: "Warganab Regular",
          price: "AED 52.00",
          img: warganabRegular,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 17,
          name: "Warganab Special",
          price: "AED 63.00",
          img: warganabSpecial,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
          id: 18,
          name: "TORTILLA WRAPS",
          price: "AED 84.00",
          img: tortillaWraps,
          category: "savories sweets",
          description: "",
          query: "savories sweets",
        },
        {
            id: 19,
            name: "Fuala Sandwich" ,
            price: "AED 60.00",
            img: fualaSandwich,
            category: "savories sweets",
            description: "",
            query: "savories sweets",
        }
      ],
    },
  ],
};
